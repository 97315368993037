export default function IconLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 16 16"
      id="Arrows-Button-Left--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Arrows Button Left Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.512500000000001 13.775000000000002 4.205 7.763300000000001a0.7250000000000001 0.7250000000000001 0 0 1 0 -1.0266000000000002L10.512500000000001 0.7250000000000001"
        strokeWidth={1.5}
      />
    </svg>
  );
}
